// Custom Styles Go here
// Global Styles

html {
	overflow-x: hidden;
}

body {
	h1,h2,h3,h4,h5,h6, .nav-link { font-family: 'ABeeZee', sans-serif; }
	p, li { font-family: 'Crete Round', serif; font-size: 18px; }
	.nomarpad {
		padding: 0 !important;
		margin: 0 !important;
	}
	.no-underline { text-decoration: none !important; }
	.section-padding { padding: 46px 10px; }
	
	.border-box-white { background: #fff; }
	.border-box-orange { background: #fcb141; }
	.border-box-orange,
	.border-box-white {
		padding: 2px;
		max-width: 65px;
		margin: 0 auto;
		text-align: center;
	}
	a.btn-c-green {
		background-color: #869c66;
		color: #fff;
		border: 1px solid #779052;
		&:hover { background-color: #779052; }
	}
	
	.first { 
		color: #ffffff;
		background: #ccc;
		font-weight: bold;
		border-left: 4px solid #fcb141;
		td {
			text-shadow: 2px 1px 2px #222;
		}
	}
}


// Site Styles
header {
	nav#top-nav {
		background-color: #fcb141;
		ul {
			li {
				&:nth-child(n+2):before {
					content: "/";
					padding: 0 8px;
					color: #fff;
				}
				a {
					color: #fff;
					font-size: 16px;
					&:hover { text-decoration: none; }
				}
			}
		}
	}
}

.main-nav {
	width: 100%;
	a {
		color: #222 !important;
		-moz-transition: .15s color ease-in-out !important;
		-o-transition: .15s color ease-in-out !important;
		-webkit-transition: .15s color ease-in-out !important;
		transition: .15s color ease-in-out !important;
		&:hover {
			color: #fcb141 !important;
		}
	}
}

section.h-slide {
	background-image: url(../img/slideshow/slide-bg.jpg);
	background-attachment: fixed;
	margin: 0 -15px;
}

section#call-out {
	position: relative;
	padding: 16.6px 0;
	width: 100%;
	background-attachment: fixed;
	background-image: url(../img/slideshow/slide-bg.jpg);
}

section#s-about {
	background-color: #fcb141;
	border-bottom: 4px solid orange;
	color: #222;
    margin: 0 -15px;
    margin-top: 0 !important;
}
section#testimonials {
	position: relative;
	background-color: #fcb141;
	background-image: url('../img/testimonial-bg.png');
	background-size: cover;
	border-top: 2px solid #de9715;
	color: #222;
    margin: 0 -15px;
    margin-top: 0 !important;
	.box-border {
		background-color: #fff;
		padding: 2px;
		max-width: 160px;
		margin: 0 auto;
	}
	.container { position: relative; }
	.carousel-indicators {
		bottom: -50px !important;
		left: -70px !important;
		li {
			width: 15px !important;
			height: 15px !important;
		}
		.active { background-color: orange !important; }
	}
	.reviews-button-container {
	  position: absolute;
	  left: 50%;
	  transform: translate(-50%,-50%);
		h1 {
			a {
				color: #222;
			}
		}
	}
}
section#s-serviceBar {
	article {
		padding: 16px 0;
		-moz-transition: .16s all ease-in-out;
		-webkit-transition: .16s all ease-in-out;
		-o-transition: .16s all ease-in-out;
		transition: .16s all ease-in-out;
	}
	a {
		color: black;
	}
}

section#contact-form-s {
	background-image: url('../img/hex-bg.jpg');
	background-position: 50% 50%;
	background-color: #fcb141;
	margin: 0 -15px;
	border-bottom: 2px solid #B56F07;
	input,
	textarea {
		border-bottom: 4px solid #F09610;
		border-radius: 0;
	}
}
section#massage-intro {
	ul {
		li {
			margin: 10px 0;
		}
	}
}

section#map-location {
	margin: 0 -15px;
	
	.map-responsive {
		overflow:hidden;
		padding-bottom: 400px;
		position:relative;
		height:0;
		iframe {
			left:0;
			top:0;
			height:100%;
			width:100%;
			position:absolute;
			max-height: 400px;
			
		}
	}
}

section#hours-rates {
	position: relative;
	background-image: url('../img/hex-bg.jpg');
	background-position: 50% 50%;
	background-color: #F09610;
	margin: 0 -15px;
	color: #fff;
	.arrow-down-icon {
		text-align: center;
		img {
			position: relative;
			margin-top: -30px;
			z-index: 0;
			max-width: 100px;
		}
	}
	article.special-box {
		-webkit-transition: .16s all ease-in-out;
		-moz-transition: .16s all ease-in-out;
		-o-transition: .16s all ease-in-out;
		transition: .16s all ease-in-out;
		img { max-width: 200px; }
	}
	
	&:hover { 
		article.special-box { 
			right: -30px;
			transform: rotate( 4deg );
		}
	}
	// Hours Table Styles
	
	table {
		border-bottom: 4px solid orange;
		.pop-top {
			padding: 0;
			.pop-top-text {
				max-width: 260px;
				background: #4057a1;
				padding: 10px;
				text-align: center;
				border-radius: 10px 10px 0 0;
				color: #fff;
    			font-weight: bold;
			}
		}
		.pop {
			background-color: #4056a1;
			color: #fff;
			border: 1px solid #4c66b7;
		}
		thead {
			tr {
				th {
					background-color: rgba(93, 92, 97, .8);
					border: none;
					padding: 20px 10px;
				}
			}
		}
		tbody {
			tr {
				td {
					background-color: rgba(197, 203, 227, .8);
					border: none;
					color: #222;
					vertical-align: middle;
					padding: 20px 10px;
					max-width: 100px;
				}
			}
		}
	}
}

section#s-map {
	position: relative;
	margin: 0 -15px;
	#map-image {
	background-image: url('../img/map.png');
	background-size: cover;
	background-position: 50% 50%;
	height: 360px;
	-webkit-transition: .15s all ease-in-out;
	-moz-transition: .15s all ease-in-out;
	-o-transition: .15s all ease-in-out;
	-ms-transition: .15s all ease-in-out;
	transition: .15s all ease-in-out;
		
		&:hover {
		/* IE 8 */
		  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";

		  /* IE 5-7 */
		  filter: alpha(opacity=50);

		  /* Netscape */
		  -moz-opacity: 0.5;

		  /* Safari 1.x */
		  -khtml-opacity: 0.5;

		  /* Good browsers */
		  opacity: 0.5;
		}
	}
	.map-content {
		position: absolute;
	  left: 50%;
	  top: 50%;
	  transform: translate(-50%,-50%);
		h1 {
			a {
				color: #222;
			}
		}
	}
}

.honeycombs{
	position: relative;
	overflow: hidden;
	width: 100%;
	text-align: center;
}

section#test-intro {
	background-image: url('../img/hex-bg-white.jpg');
	background-size: 100%;
	background-color: #fff;
	margin: 0 -15px;
}
footer {
	background-image: url('../img/hex-bg-white.jpg');
	background-position: 50% 50%;
	background-color: #fff;
	position: relative;
	padding: 56px 0;
	border-top: 4px solid orange;
	
	#arrow-up-top {
		text-align: center;
		span {
			color: orange;
		}
	}
}